import React, { useEffect, useState } from 'react';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './selected_study/Home';
import Stats from './study_plan/Stats';
import DiagnosticStats from './study_plan/DiagnosticStats';


import JLPTExperienceStep from './onboarding/JLPTExperienceStep';
import TargetLevelStep from './onboarding/TargetLevelStep';
import LevelTestIntro from './onboarding/LevelTestIntro';
import StudyPlan from './study_plan/StudyPlan';
import DiagnosticExam from './study_plan/DiagnosticExam';
import Exam from './selected_study/Exam';
import Login from './Login';
import Signup from './onboarding/SignUp';
import EmailVerification from './onboarding/EmailVerification';
import ReviewExam from './selected_study/ReviewExam';
import ReviewPersonalizedExam from './study_plan/ReviewPersonalized';
import AskMina from './AskMina';
import AdBlockDetector from './components/AdBlockDetector';
import ForgotPassword from './onboarding/forgotpassword';
import ResetPassword from './onboarding/resetpassword';
import DiagnosticExamResultsPage from './dashboard/DiagnosticExamsResultsPage'
import PersonalizedExamResultsPage from './dashboard/PersonalizedExamsResultsPage'
import ReviewQuestion from './study_plan/ReviewQuestion';
import ReviewExamQuestion from './selected_study/ReviewExamQuestion';


import UsersListPage from './dashboard/UsersListPage';

import DiagnosesExamDetail from './dashboard/DiagnosesExamDetail';
import CreateStudyPlan from './dashboard/CreateStudyPlan';
import UserExamsPage from './dashboard/UserExamsPage';
import DiagnosticExams from './dashboard/DiagnosticExams';





import './css/App.css';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import MyCheckoutComponent from './MyCheckoutComponent';
import PersonalizedExam from './study_plan/PersonalizedExam';
import QuizOfTheDay from './components/QuizOfTheDay';
import AskMinaHome from './AskMinaHome';

const stripePromise = loadStripe('pk_test_51PCLezBQK9NeVKEQ9UZaCLagoap3lORt7iIs0j2mZAmnVBCHh1D3hYr8h9A7UlXVRG8w1PVdaA92qxLDZjOuLRCn002sOLsGHy');

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Check for JWT in local storage
    const jwt = localStorage.getItem('jwt');
    setIsAuthenticated(!!jwt);
  }, []);

  if (!isAuthenticated) {
    // If not authenticated, redirect to login page without the topbar
    return (
      <Router>
        <Routes>
          <Route path="*" element={<Login />} />
          <Route path="/register" element={<Signup />} />
          <Route path="/email-verification" element={<EmailVerification />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          
        
        </Routes>
      </Router>
    );
  }

  return (
    <Router>
      <div className="App">
      <AdBlockDetector />
        <Routes>
        <Route path="/jlpt-experience" element={<JLPTExperienceStep />} />
        <Route path="/target-level" element={<TargetLevelStep />} />
        <Route path="/level-test" element={<LevelTestIntro />} />
        <Route path="/stats" element={<Stats/>} />
        <Route path="/diagnostic-stats" element={<DiagnosticStats/>} />
          <Route path="/selected_study" element={<Home />} />
          <Route path="/" element={<StudyPlan/>} />
          <Route path="/exam/:exam_id" element={<Exam />} />
          <Route path="/personalized-exam/:exam_id" element={<PersonalizedExam />} />
          <Route path="/diagnostic-exam/:exam_id" element={<DiagnosticExam />} />
          <Route path="/askmina" element={<AskMina />} />
          <Route path="/review-exam/:exam_id" element={<ReviewExam />} />
          <Route path="/review-personalized/:exam_id" element={<ReviewPersonalizedExam />} />
          <Route path="/review-question/:exam_id" element={<ReviewQuestion />} />
          <Route path="/review-exam-question/:exam_id" element={<ReviewExamQuestion />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Signup />} />
          <Route path="/exam/:examId/users" element={<UsersListPage />} />
          <Route path="/person/:id/exam-results" element={<DiagnosesExamDetail />} />
          
          <Route path="/DiagnosticExams" element={<DiagnosticExams />} />
          <Route path="/user/:userId/exams" element={<UserExamsPage />} />
          <Route path="/diagnostic-exam-results/:userId/:examId" element={<DiagnosticExamResultsPage />} />
          <Route path="/personalized-exam-results/:userId/:examId" element={<PersonalizedExamResultsPage />} />


          
          
          <Route path="/create-study-plan/:userId" element={<CreateStudyPlan />} />
          <Route path="/checkout" element={
            <Elements stripe={stripePromise}>
              <MyCheckoutComponent />
            </Elements>
          } />
          <Route path="/quiz-of-the-day" element={<QuizOfTheDay />} />
          <Route path="/ask-mina" element={<AskMinaHome />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
