import React, { useState, useEffect } from 'react';
import { Link, useLocation,  } from 'react-router-dom';
import '../css/Sidebar.css';
import Logo from '../assets/images/logo.png';
import RecommendedStudyIcon from '../assets/icons/recommendedstudy.png';
import SelectedStudyIcon from '../assets/icons/selectedstudy.png';
import Mina from '../assets/images/mina.png';
import QuizIcon from '../assets/icons/question.png';
// import AskMinaHome from '../AskMinaHome';
import askIcon from '../assets/icons/askicon.png';

function Sidebar({ 
    stages, 
    currentStageIndex, 
    onStageClick, 
    getStageClassName, 
    getStageContent, 
    hideTrainings = false,
    isMobileMenuOpen,
    onMobileMenuClose,
    onLogout,
    onGetFullAccess,
    userStatus
}) {
    const location = useLocation();
    // const navigate = useNavigate();
    const isSelectedStudy = location.pathname === '/selected_study';
    const [userStreak, setUserStreak] = useState(null);
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        const fetchUserData = async () => {
            const jwt = localStorage.getItem('jwt');
            try {
                // Fetch user streak
                const streakResponse = await fetch('https://api.getmina.co/api/v1/panel/user-streak', {
                    headers: { 'Authorization': `Bearer ${jwt}` },
                    method: "GET",
                });
                const streakData = await streakResponse.json();
                setUserStreak(streakData);

                // Fetch user data
                const userResponse = await fetch("https://api.getmina.co/api/v1/auth/me", {
                    headers: { 'Authorization': `Bearer ${jwt}` },
                    method: "GET",
                });
                const userData = await userResponse.json();
                setUserData(userData);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchUserData();
    }, []);

    // const handleAskMinaClick = () => {
    //     navigate('/ask-mina');
    //     if (onMobileMenuClose) {
    //         onMobileMenuClose();
    //     }
    // };

    return (
        <>
            {/* Overlay for mobile */}
            <div 
                className={`sidebar-overlay ${isMobileMenuOpen ? 'active' : ''}`} 
                onClick={onMobileMenuClose}
            />
            
            <div className={`sidebar ${isMobileMenuOpen ? 'mobile-active' : ''}`}>
                <div className="sidebar-header">
                    <img src={Logo} alt="Logo" className="sidebar-logo desktop-only" />
                    <img src={Mina} alt="Mina Logo" className="sidebar-logo mobile-only" />
                    
                    <nav className="sidebar-nav">
                        <Link 
                            to="/study_plan" 
                            className={`sidebar-nav-link ${location.pathname === '/study_plan' ? 'active' : ''}`}
                            onClick={onMobileMenuClose}
                        >
                            <img 
                                src={RecommendedStudyIcon} 
                                alt="" 
                                className={`nav-icon ${location.pathname === '/study_plan' ? 'active' : ''}`}
                            />
                            Recommended Study
                        </Link>
                        <Link 
                            to="/selected_study"
                            className={`sidebar-nav-link ${location.pathname === '/selected_study' ? 'active' : ''}`}
                            onClick={onMobileMenuClose}
                        >
                            <img 
                                src={SelectedStudyIcon} 
                                alt="" 
                                className={`nav-icon ${location.pathname === '/selected_study' ? 'active' : ''}`}
                            />
                            Selected Study
                        </Link>
                        <Link 
                            to="/quiz-of-the-day" 
                            className={`sidebar-nav-link ${location.pathname === '/quiz-of-the-day' ? 'active' : ''}`}
                            onClick={onMobileMenuClose}
                        >
                            <img 
                                src={QuizIcon} 
                                alt="" 
                                className={`nav-icon ${location.pathname === '/quiz-of-the-day' ? 'active' : ''}`}
                            />
                            Quiz of the Day
                        </Link>
                        <Link 
                            to="/ask-mina"
                            className={`sidebar-nav-link ${location.pathname === '/ask-mina' ? 'active' : ''}`}
                            onClick={onMobileMenuClose}
                        >
                            <img 
                                src={askIcon} 
                                alt="Ask Mina" 
                                className={`nav-icon ${location.pathname === '/ask-mina' ? 'active' : ''}`}
                            />
                            Ask Mina
                        </Link>
                        {userStreak && (
                            <div className="streak-container">
                                <span className="streak-count">{userStreak.streak_count} day streak! 🔥</span>
                            </div>
                        )}
                    </nav>
                </div>
                
                {/* Only show trainings in sidebar for mobile */}
                {!hideTrainings && !isSelectedStudy && stages && stages.length > 0 && (
                    <div className="sidebar-content mobile-only">
                        <div className="mobile-trainings-header">My Trainings</div>
                        <div className="mobile-trainings-list">
                            {stages.map((stage, index) => (
                                <div 
                                    key={stage.stage_id}
                                    className={`mobile-training-item ${getStageClassName(index)}`}
                                    onClick={() => {
                                        onStageClick(index);
                                        onMobileMenuClose();
                                    }}
                                >
                                    {index === 0 ? "Diagnostic Test" : `Chapter ${index}`}
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {/* User info section - Modified to always show */}
                <div className="user-info">
                    {userData && <span className="user-email">{userData.email}</span>}
                    {userStatus !== 'active' && (
                        <button 
                            className="get-access-button"
                            onClick={onGetFullAccess}
                        >
                            Get Full Access
                        </button>
                    )}
                    <button 
                        className="logout-button"
                        onClick={onLogout}
                    >
                        Logout
                    </button>
                </div>
            </div>
        </>
    );
}

export default Sidebar; 