import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/Exam.css';
import BeatLoader from 'react-spinners/BeatLoader';
import AskMina from '../AskMina';
import mixpanel from '../mixpanel';
import useMediaQuery from '@mui/material/useMediaQuery';
import cancelIcon from '../assets/icons/cancel.png';

function QuizOfTheDay() {
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery('(max-width: 768px)');
  const [content, setContent] = useState([]);
  const [quizId, setQuizId] = useState(null);
  const [currentContentIndex, setCurrentContentIndex] = useState(0);
  const [isAnswerRevealed, setIsAnswerRevealed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showAskMina, setShowAskMina] = useState(false);
  const [answeredQuestions, setAnsweredQuestions] = useState({});
//   const [redirectToTargetLevel, setRedirectToTargetLevel] = useState(false);

  useEffect(() => {
    const fetchQuizOfTheDay = async () => {
      try {
        const selectedLevel = localStorage.getItem('selectedLevel');
        
        // If no level is selected, redirect to target level selection
        // if (!selectedLevel) {
        // //   navigate('/target-level');
        // selectedLevel = "N3";
        //   return;
        // }

        setLoading(true);
        const jwt = localStorage.getItem('jwt');
        const response = await fetch('https://api.getmina.co/api/v1/panel/get-quiz-of-the-day', {
          headers: { 
            'Authorization': `Bearer ${jwt}`,
            'Content-Type': 'application/json'
          },
          method: 'POST',
          body: JSON.stringify({ 
            level: selectedLevel
          })
        });
        const data = await response.json();
        
        if (data.error === 'No quiz available for today') {
          alert('You have already completed your daily exam!');
          navigate('/'); // Navigate back to home
          return;
        }

        setContent(data.questions.map(question => ({ ...question, selectedAnswer: null, isAnswered: false })));
        setQuizId(data.quiz_id);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching quiz:', error);
        setError('Error fetching quiz');
        setLoading(false);
      }
    };

    fetchQuizOfTheDay();
  }, [navigate]); // Add navigate to dependencies

  const handleAnswerSelection = (questionId, index) => {
    if (!isAnswerRevealed) {
      console.log("Setting answer:", questionId, index);
      setAnsweredQuestions(prev => ({
        ...prev,
        [questionId]: index
      }));
    }
  };

  const handleRevealAnswer = () => {
    setIsAnswerRevealed(true);
  };

  const handleNext = () => {
    if (currentContentIndex < content.length - 1) {
      setCurrentContentIndex(prev => prev + 1);
      setIsAnswerRevealed(false);
    } else {
      // Submit quiz when reaching the last question
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    try {
      const jwt = localStorage.getItem('jwt');
      
      // Format the responses as required by the API
      const responses = Object.entries(answeredQuestions).map(([questionId, answerIndex]) => ({
        question_id: parseInt(questionId),
        selected_answer: content.find(q => q.question_id.toString() === questionId).choices.choices[answerIndex]
      }));

      const response = await fetch('https://api.getmina.co/api/v1/panel/submit-quiz-of-the-day', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${jwt}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          quiz_id: quizId,
          responses: responses
        })
      });
console.log("QUIZ ID",quizId);
console.log("QUIZ ID",content);


console.log("RESPONSE",response.body);
console.log("RESPONSE",response);

      if (!response.ok) {
        throw new Error('Failed to submit quiz');
      }

      // Track completion in mixpanel
      mixpanel.track('Quiz of the Day Completed', {
        quiz_id: quizId,
        questions_answered: Object.keys(answeredQuestions).length
      });

      // Show success message
      alert('Quiz submitted successfully!');
      
      // Navigate back to home
      navigate('/');
    } catch (error) {
      console.error('Error submitting quiz:', error);
      alert('Failed to submit quiz. Please try again.');
    }
  };

  const handleAskMina = () => {
    setShowAskMina(true);
  };

  const handleReportIssue = () => {
    // Implement report issue functionality if needed
    console.log('Report issue clicked');
  };

  if (loading) return <div className="loader-container"><BeatLoader color="#3E00EF" /></div>;
  if (error) return <div>{error}</div>;
  if (!content || content.length === 0) return null; // Changed to return null since we're handling the navigation

  return (
    <div className="Exam">
      <div className="upper-bar">
        <div onClick={() => navigate('/')} aria-label="cancel" className="cancel-button-exam">
          <img src={cancelIcon} alt="Cancel" />
        </div>
        <div className="upper-right">Remaining Questions: {content.length - currentContentIndex}</div>
      </div>
      
      <div className="content">
        <div className="left-section">
          <div className='question-title'>Question {currentContentIndex + 1}</div>
          {content[currentContentIndex]?.instruction && (
            <p className="instruction">{content[currentContentIndex].instruction}</p>
          )}
          {content[currentContentIndex]?.script && content[currentContentIndex].script !== "0" ? (
            <div className="passage-box">
              {content[currentContentIndex].script}
            </div>
          ) : (
            <hr className="question-divider" />
          )}
          <div className="question-answer">
            <div className="question-text">{content[currentContentIndex].question}</div>
            <div className="answer-section">
              {content[currentContentIndex].choices.choices.map((choice, choiceIndex) => (
                <div
                  key={choiceIndex}
                  className={`answer-option 
                    ${answeredQuestions[content[currentContentIndex].question_id] === choiceIndex ? 'selected' : ''} 
                    ${isAnswerRevealed ? (choice === content[currentContentIndex].answer ? 'correct' : (answeredQuestions[content[currentContentIndex].question_id] === choiceIndex ? 'incorrect' : '')) : ''}`}
                  onClick={() => !isAnswerRevealed && handleAnswerSelection(content[currentContentIndex].question_id, choiceIndex)}
                >
                  {choice}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="lower-bar">
        <div className="lower-left">
          <button className="report-issue-button" onClick={handleReportIssue}>Report Issue</button>
        </div>
        {!isSmallScreen && (
          <div className="lower-center">
            <button className="ask-mina-button" onClick={handleAskMina}>Ask Mina</button>
          </div>
        )}
        <div className="lower-right">
          <button 
            className="previous-button" 
            onClick={() => setCurrentContentIndex(prev => prev - 1)} 
            disabled={currentContentIndex === 0}
          >
            Previous
          </button>
          <button 
            className="reveal-answer-button" 
            onClick={isAnswerRevealed ? handleNext : handleRevealAnswer}
            disabled={answeredQuestions[content[currentContentIndex].question_id] === undefined}
          >
            {isAnswerRevealed ? (currentContentIndex === content.length - 1 ? "Finish" : "Next") : "Check Answer"}
          </button>
        </div>
      </div>

      {/* Floating Ask Mina button for mobile */}
      {isSmallScreen && (
        <button className="floating-ask-mina-button" onClick={handleAskMina}>
          Ask Mina
        </button>
      )}

      {showAskMina && (
        <div className="ask-mina-overlay">
          <div className="ask-mina-modal">
            <button className="close-ask-mina" onClick={() => setShowAskMina(false)}>×</button>
            <AskMina
              questionIds={[content[currentContentIndex].question_id]}
              questionnumber={currentContentIndex + 1}
              revealed={isAnswerRevealed}
              initialChatHistory={[]}
              onClose={() => setShowAskMina(false)}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default QuizOfTheDay; 