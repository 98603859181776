import React, { useState, useEffect,  } from 'react';
import './css/AskMinaHome.css';
// import { useNavigate } from 'react-router-dom';
import arrow from './assets/icons/askminaarrow.png';
import Sidebar from './components/Sidebar';
import { FaBars } from 'react-icons/fa';

import mixpanel from './mixpanel';

// const ASK_MINA_HELP_URL = "https://api.getmina.co/api/v1/panel/get_helpopen";
const ASK_MINA_HELP_URL2 = "https://api.getmina.co/api/v1/panel/ask-mina-help";
const NORMAL_API_URL = "https://api.getmina.co/api/v1/panel/test-convo";

function AskMinaHome({ questionId, initialChatHistory, questionnumber, onClose }) {
    // const navigate = useNavigate();
    const [input, setInput] = useState('');
    const [displayChatHistory, setDisplayChatHistory] = useState(() => {
        if (initialChatHistory && initialChatHistory.length > 0) {
            return [initialChatHistory[initialChatHistory.length - 1]]; // Start with only the last message
        }
        return [];
    });
    const [responseHistory, setResponseHistory] = useState([]); // Store extracted "history" parts
    const [isLoading, setIsLoading] = useState(false); // Loading state
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);

    const suggestions = [
        {
            title: 'Vocabulary',
            description: 'Interact with Mina to enhance your vocabulary, tackle word challenges, and master language nuances.'
        },
        {
            title: 'Grammar',
            description: 'Interact with Mina to enhance your vocabulary, tackle word challenges, and master language nuances.'
        },
        {
            title: 'Reading',
            description: 'Collaborate with Mina to dive deep into JLPT reading, sharpen your comprehension of short, mid-size and long passages.'
        },
        {
            title: 'General Information',
            description: 'Consult Mina for essential details and guidance during your test prep journey.'
        }
    ];

    const handleSuggestionClick = (suggestion) => {
        setInput(suggestion.title);
        sendInput(suggestion.title);
    };

    const sendInput = async (userInput = input) => {
        if (!userInput.trim() || isLoading) return;

        setIsLoading(true);
        setInput(''); // Clear input field

        // Add user message to chat
        const newUserMessage = { role: 'user', content: userInput };
        setDisplayChatHistory(prev => [...prev, newUserMessage]);

        try {
            const requestBody = {
                user_input: userInput,
                history: responseHistory,
            };

            mixpanel.track('Ask Mina', {
                user_input: userInput,
            });

            const url = responseHistory.length === 0 ? ASK_MINA_HELP_URL2 : NORMAL_API_URL;

            const jwt = localStorage.getItem('jwt');
            const response = await fetch(url, {
                method: "POST",
                headers: { 
                    'Authorization': `Bearer ${jwt}`, 
                    "Content-Type": "application/json" 
                },
                body: JSON.stringify(requestBody)
            });

            const reader = response.body.getReader();
            let currentDisplayContent = '';
            let fullResponse = '';

            function processText({ done, value }) {
                if (value) {
                    const chunk = new TextDecoder("utf-8").decode(value, { stream: !done });
                    fullResponse += chunk;

                    const jsonStartIndex = chunk.indexOf('{"history":');
                    if (jsonStartIndex !== -1) {
                        currentDisplayContent += chunk.substring(0, jsonStartIndex);
                    } else {
                        currentDisplayContent += chunk;
                    }

                    setDisplayChatHistory(prev => {
                        const newHistory = [...prev];
                        if (newHistory.length > 0 && newHistory[newHistory.length - 1].role === 'system') {
                            newHistory[newHistory.length - 1].content = currentDisplayContent;
                        } else {
                            newHistory.push({ role: 'system', content: currentDisplayContent });
                        }
                        return newHistory;
                    });
                }

                if (!done) {
                    return reader.read().then(processText);
                } else {
                    const historyMatch = fullResponse.match(/{"history":\s*\[.*\]}/);
                    if (historyMatch) {
                        const history = JSON.parse(historyMatch[0]).history;
                        setResponseHistory(history);
                    }

                    const historyIndex = currentDisplayContent.indexOf('{"history":');
                    if (historyIndex !== -1) {
                        currentDisplayContent = currentDisplayContent.substring(0, historyIndex);
                    }

                    setDisplayChatHistory(prev => {
                        const newHistory = [...prev];
                        if (newHistory.length > 0 && newHistory[newHistory.length - 1].role === 'system') {
                            newHistory[newHistory.length - 1].content = currentDisplayContent;
                        } else {
                            newHistory.push({ role: 'system', content: currentDisplayContent });
                        }
                        return newHistory;
                    });

                    setIsLoading(false);
                }
            }

            reader.read().then(processText);
        } catch (error) {
            console.error("Error fetching Mina's response:", error);
            setIsLoading(false);
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            sendInput();
        }
    };

    // const handleBack = () => {
    //     onClose(displayChatHistory) ; // Pass the updated chatHistory back to the parent component
    //  };

    // Add resize listener
    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className="page-container">
            {isMobileView && (
                <FaBars 
                    className="mobile-menu-icon" 
                    onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                />
            )}
            
            <Sidebar 
                isMobileMenuOpen={isMobileMenuOpen}
                onMobileMenuClose={() => setIsMobileMenuOpen(false)}
            />
            
            <div className="main-content">
                <div className="askminahome-content">
                    
                    
                    {displayChatHistory.length === 0 ? (
                        <>
                            <h1 className="main-question">How can I help you today?</h1>
                            <div className="suggestions-grid">
                                {suggestions.map((suggestion, index) => (
                                    <div 
                                        key={index} 
                                        className="suggestion-box"
                                        onClick={() => handleSuggestionClick(suggestion)}
                                    >
                                        <h3>{suggestion.title}</h3>
                                        <p>{suggestion.description}</p>
                                    </div>
                                ))}
                            </div>
                        </>
                    ) : (
                        <div className="chat-container">
                            {displayChatHistory.map((message, index) => (
                                <div key={index} className={`chat-message ${message.role}`}>
                                    {message.content}
                                </div>
                            ))}
                        </div>
                    )}
                    
                    <div className="input-container">
                        <input
                            type="text"
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            onKeyPress={handleKeyPress}
                            placeholder="Type your message..."
                            className="message-input"
                        />
                        <button 
                            className="send-button"
                            onClick={() => sendInput()}
                            disabled={isLoading || !input.trim()}
                        >
                            <img src={arrow} alt="Send" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AskMinaHome;
